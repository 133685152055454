import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/login";
import Recall from "./components/recall";
import ServiceOrder from "./components/ServiceOrder";
import ServiceOrderList from "./components/ServiceOrderList";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/sign-in" component={Login} />
        <ProtectedRoute path="/recall" component={Recall} />
        <ProtectedRoute path="/ServiceOrderList" component={ServiceOrderList} />
        <ProtectedRoute path="/ServiceOrder" component={ServiceOrder} />
        <Route path="*" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
